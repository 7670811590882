// @ts-ignore
import { Elm as ElmMain } from "./elm/Main.elm";
import { Elm as ElmError } from "./elm/Error.elm";
import { Auth0Result, Auth0UserProfile, WebAuth } from "npm:auth0-js";
import { User, UserManager } from "oidc-client-ts";

/**
 * @typedef {object} ExtendedUserProperties
 * @property {object} profile
 * @property {object} profile.organization
 * @property {object} profile.organization.CarbonCloud
 * @property {string[]} profile.organization.CarbonCloud.auth0Groups
 * @property {object} profile.resource_access
 * @property {object} profile.resource_access.CarbonCloud
 * @property {string[]} profile.resource_access.CarbonCloud.roles
 * @typedef {User & ExtendedUserProperties} ExtendedUser
 *
 */

// @ts-ignore
const config = window.config;

const enableKeycloak = config.keycloakEnabled;

const url = window.location.origin;

/**
 * @type {import("oidc-client-ts").UserManagerSettings}
 */
const oidcSettings = {
  authority: config.keycloakRealm,
  client_id: config.keycloakClientId, 
  redirect_uri: url + "/callback",
  response_type: "code",
  post_logout_redirect_uri: url + "/logout",
};

/**
 * @type {import("oidc-client-ts").UserManager}
 */
const userManager = new UserManager(oidcSettings);

class ChartjsChart extends window.HTMLElement {
  constructor() {
    const self = super();
    this._chartConfig = {};
    // @ts-ignore
    return self;
  }

  connectedCallback() {
    const canvas = document.createElement("canvas");
    this._canvas = canvas;
    this.appendChild(canvas);

    const ctx = canvas.getContext("2d");
    this.style.display = "block";
    // @ts-ignore
    this._chart = new window.Chart(ctx, this._chartConfig);
  }
  // @ts-ignore
  set chartConfig(newValue) {
    this._chartConfig = newValue;

    if (this._chart) {
      const newDatasets = newValue.data.datasets;
      const oldDatasets = this._chart.data.datasets;

      // Update all datasets
      for (let i = 0; i < newDatasets.length; i++) {
        // Copying the meta will keep the animations between them smooth
        if (oldDatasets[i]) {
          newDatasets[i]._meta = oldDatasets[i]._meta;
        }
        oldDatasets[i] = newDatasets[i];
      }

      // Update options and then call ChartJs update
      this._chart.options = newValue.options;
      this._chart.data = newValue.data;
      this._chart.update();
    }
  }
}

window.customElements.define("chart-component", ChartjsChart);

/**
 * @typedef {object} UserProfile
 * @property {string} userId
 * @property {string} name
 * @property {string} email
 * @property {string?} avatarUrl
 */

/**
 * @typedef {object} Flags
 * @property {string} authToken
 * @property {string} apiRoot
 * @property {UserProfile} userProfile
 * @property {string} nominatimToken
 * @property {string[]} roles
 * @property {string[]} groups
 * @property {string[]} permissions
 * @property {string | null} selectedOrganization
 * @property {string} climateApiRootUrl
 * @property {string} climateHubRootUrl
 * @property {string} climateLabelRootUrl
 */

const webAuth = new WebAuth(config.auth0Config);

/**
 * @returns {boolean} Authentication state
 */
function isAuthenticated() {
  const expiresAt = JSON.parse(localStorage.getItem("expires_at") || "{}");
  return new Date().getTime() < expiresAt;
}

/**
 *
 * @param {number} expiresIn
 * @returns {number} expiresAt
 */
const expiresInToExpiresAt = (expiresIn) =>
  expiresIn * 3600000 + new Date().getTime();

/**
 *
 * @param {{accessToken: string, idToken: string, expiresIn: number}} session
 */
function setSession({ accessToken, idToken, expiresIn }) {
  // Set the time that the access token will expire at
  const expiresAt = JSON.stringify(expiresInToExpiresAt(expiresIn));
  localStorage.setItem("access_token", accessToken);
  localStorage.setItem("id_token", idToken);
  localStorage.setItem("expires_at", expiresAt);
  localStorage.setItem("token", accessToken);
}

/**
 *
 * @param {Auth0Result} authResult
 * @returns {{accessToken: string, idToken: string, expiresIn: number}?}
 */
const validatedAuth0Result = ({ accessToken, idToken, expiresIn }) =>
  !!accessToken && !!idToken && !!expiresIn
    ? { accessToken, idToken, expiresIn }
    : null;

/**
 *
 * @param {HTMLElement} e
 * @param {string} buttonIdToLookFor
 * @param {number} ancestorEndurance
 *
 * @returns {boolean}
 */
const closestWithLimit = (e, buttonIdToLookFor, ancestorEndurance) => {
  if (!e || ancestorEndurance <= 0) {
    return false;
  }
  if (e.id == buttonIdToLookFor) {
    return true;
  } else {
    const decreasedEndurance = ancestorEndurance - 1;
    // @ts-ignore
    return closestWithLimit(
      e.parentNode,
      buttonIdToLookFor,
      decreasedEndurance
    );
  }
};

/**
 *
 * @param {Flags?} flags
 */
const startApp = (flags) => {
  const app = ElmMain.Main.init({ flags });

  /**
   * @constant {HTMLBodyElement}
   */
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.addEventListener("click", bodyOnClickHandler, true);
  bodyElement.addEventListener("dragover", (ev) => ev.preventDefault(), true);

  /**
   *
   * @param {MouseEvent} e
   */
  function bodyOnClickHandler(e) {
    handleCopyButton(e.target, 6);
    const buttonIdToLookFor = "copy-calc-values";
    // Avoid more elegant/standard solution with e.target.closest due to performance issues
    // since *all* click events inside body will run this code
    // This means that this is easily-broken by html changes
    var ancestorEndurance = 6;
    // @ts-ignore
    if (closestWithLimit(e.target, buttonIdToLookFor, ancestorEndurance)) {
      var copyText = document.querySelector(
        "#" + buttonIdToLookFor + "-hidden"
      );
      // @ts-ignore
      copyText.select();
      document.execCommand("copy");
    }
  }

  /**
   * @param {MouseEvent} e
   * @param {Integer} endurance
   * @returns {undefined}
   *
   * this is needed because elm messages aren't user initiated and some browsers
   * block access to the clipboard when it's not a user-initiated event.
   */
  function handleCopyButton(e, endurance) {
    if (endurance <= 0) {
      return;
    }
    const magicCopyClass = "copy-button";
    // @ts-ignore
    if (e && e.classList && !e.classList.contains(magicCopyClass)) {
      return handleCopyButton(e.parentNode, endurance - 1);
    }
    // @ts-ignore
    const copyButtonId = e.id;
    if (!copyButtonId) return;
    const selector = "#" + copyButtonId + "-text";
    var copyText = document.querySelector(selector);
    // @ts-ignore
    copyText.select();
    document.execCommand("copy");
  }

  app.ports.logout.subscribe(function () {
    if (enableKeycloak) {
      userManager.removeUser();
      // redirect to the logout page
      userManager.signoutRedirect({ post_logout_redirect_uri: url + "/logout"});
    } else {
      setSession({
        expiresIn: -10000,
        accessToken: "",
        idToken: "",
      });
      webAuth.logout({
        returnTo: window.origin,
        clientID: config.auth0Config.clientID,
      });
    }
  });

  app.ports.login.subscribe(function () {
    if (enableKeycloak) {
      userManager.signinRedirect();
    } else {
      webAuth.authorize();
    }
  });

  app.ports.openInNewTab.subscribe((url) => window.open(url, "_blank"));

  app.ports.setSelectedOrganization.subscribe((orgId) =>
    sessionStorage.setItem("carboncloud-selected-org", orgId)
  );

  app.ports.dragStart.subscribe((event) => {
    event?.dataTransfer.setDragImage(event.target, 110, 40); // Magic number to center image
  });
};

/**
 *
 * @param {string} accessToken
 * @returns {Promise<Auth0UserProfile>}
 */
const getUserProfile = (accessToken) =>
  new Promise((resolve, reject) =>
    webAuth.client.userInfo(accessToken, function (err, user) {
      if (err) reject(err);
      else resolve(user);
    })
  );

// Needed for the Safari bug below
/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 *
 * @returns {Promise<Flags?>}
 */
const flagKeycloak = () => {
  const currentPath = window.location.pathname;

  /**
   * @type {Promise<ExtendedUser | null>}
   */
  const getUser_ =
    currentPath === "/callback"
        ? /** @type {Promise<ExtendedUser | null>} */ (
          userManager.signinCallback(window.location.href).then((response) => {
            // reset the URL
            window.history.replaceState({}, document.title, "/");
            return response;
          })
        )
      : /** @type {Promise<ExtendedUser | null>} */ (userManager.getUser());

  return new Promise ((resolve, reject) => getUser_.then((user) => {
    if (user) {
       
      // Organizations are in the format "<org-id>:<org-name>"
      const groups = Object.values(user.profile?.organization).flatMap(x => x.auth0Groups.map(
        (x) => x.split(":")[0]
      ));

      // Roles are in the format "<role>"
      const roles = user.profile.resource_access.carboncloud.roles
        .map((x) => x.split(":"))
        .filter((x) => x.length === 1)
        .map(x => x[0]);

      // Permissions are in the format "carbondata:permissions:<permission-type>"
      const permissions = user.profile.resource_access.carboncloud.roles
        .map((x) => x.split(":"))
        .filter((x) => x[0] === "carbondata" && x[1] === "permissions")
        .map((x) => x[2]);

      /**
       * @type {Flags}
       */
      const flag = {
        authToken: user.access_token,
        apiRoot: config.backend,
        userProfile: {
          userId: user.profile.sub || "",
          name: user.profile.name || "",
          email: user.profile.email || "",
          avatarUrl: user.profile.picture || "",
        },
        nominatimToken: config.nominatimToken,
        roles,
        permissions,
        groups,
        selectedOrganization: sessionStorage.getItem(
          "carboncloud-selected-org"
        ),
        climateApiRootUrl: config.climateApiRootUrl,
        climateHubRootUrl: config.climateHubRootUrl,
        climateLabelRootUrl: config.climateLabelRootUrl,
      };
      resolve(flag);
    }
    resolve(null);
  }));
};

/**
 *
 * @returns {Promise<Flags?>}
 */
const flags = () =>
  new Promise((resolve, reject) => {
    webAuth.parseHash(
      { hash: window.location.hash },
      async function (err, authResult) {
        if (err) {
          reject(err);
        }
        const session = authResult ? validatedAuth0Result(authResult) : null;
        // BUG: Safari is awesome see CDAT-2696
        sleep(10).then(async () => {
          if (session !== null) {
            window.location.hash = "";
            setSession(session);
          }
          if (isAuthenticated()) {
            const authToken = localStorage.getItem("access_token");

            if (err) reject(err);
            else if (authToken !== null) {
              await getUserProfile(authToken)
                // We add this property to the user profile in a rule in Auth0
                // and it's not part of the base Auth0Profile
                // Reference: https://auth0.com/docs/rules/references/samples#add-claims-to-access-token
                // @ts-ignore
                .then((userProfile) => {
                  return userProfile[config.claimsNamespace + "roles"] !==
                    null &&
                    userProfile[config.claimsNamespace + "groups"] !== null &&
                    userProfile[config.claimsNamespace + "permissions"] !== null
                    ? resolve({
                        authToken: authToken,
                        apiRoot: config.backend,
                        userProfile: {
                          userId: userProfile.sub,
                          name: userProfile.name,
                          email: userProfile.email,
                          avatarUrl: userProfile.picture,
                        },
                        nominatimToken: config.nominatimToken,
                        roles: userProfile[config.claimsNamespace + "roles"],
                        permissions:
                          userProfile[config.claimsNamespace + "permissions"],
                        groups: userProfile[config.claimsNamespace + "groups"],
                        selectedOrganization: sessionStorage.getItem(
                          "carboncloud-selected-org"
                        ),
                        climateApiRootUrl: config.climateApiRootUrl,
                        climateHubRootUrl: config.climateHubRootUrl,
                        climateLabelRootUrl: config.climateLabelRootUrl,
                      })
                    : new Error(
                        "Roles, groups or permissions are missing in the user profile"
                      );
                })
                .catch((err) => {
                  console.error("Error[getUserProfile]:", err);
                  resolve(null);
                });
            }
          } else if (err) {
            reject({
              error: "access_denied",
              errorDescription: "Session invalid or expired.",
            });
          }
          // resolve with null we redirect us to the sign in page
          else {
            resolve(null);
          }
        });
      }
    );
  });

// Main function call that initializes and starts the application
(enableKeycloak ? flagKeycloak() : flags()).then(startApp).catch((err) => {
  const elmError = ElmError.Error.init({ flags: err });

  elmError.ports.logout.subscribe(function () {
    if (enableKeycloak) {
      userManager.removeUser();
      userManager.signoutCallback();
    } else {
      setSession({
        expiresIn: -10000,
        accessToken: "",
        idToken: "",
      });
      webAuth.logout({
        returnTo: window.origin,
        clientID: config.auth0Config.clientID,
      });
    }
  });
});
